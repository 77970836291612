body {
  margin: 0;
  color: rgb(68, 68, 68);
}

/*.active {*/
/*  color: dodgerblue;*/
/*}*/
.ant-row {
  position: relative;
  height: auto;
  margin-right: 0;
  margin-left: 0;
  zoom: 1;
  display: block;
  box-sizing: border-box;
}
.ant-col-12 {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  float: left;
}
.ant-col {
  position: relative;
  min-height: 1px;
}
